<template>
  <v-container class="pa-0 pt-10 pt-md-12 pr-0 pr-md-12">
    <span
      class="white--text"
      :class="{
        'font-18': $vuetify.breakpoint.xsOnly,
        headline: $vuetify.breakpoint.smAndUp
      }"
      >2. Vali asukoht ja kuupäev</span
    >
    <v-row>
      <v-autocomplete
        v-model="location"
        :items="locations"
        item-text="title"
        item-value="title"
        return-object
        label="Vali asukoht"
        no-data-text="Asukohta ei leitud"
        solo
        color="white"
        class="mt-3 event-select"
      >
      </v-autocomplete>
    </v-row>
    <v-row class="mr-10 mb-12">
      <v-col
        v-for="(item, index) in filteredDates"
        :key="item.id"
        cols="4"
        class="pa-0"
        :class="{ 'col-20': $vuetify.breakpoint.smAndUp }"
      >
        <div @click="setSelectedEvent(item)">
          <ChooseDateItem
            v-if="index < filteredDates.length"
            :key="item.id"
            :item="item"
          />
        </div>
      </v-col>
      <div
        class="col pa-0"
        :class="{
          'col-20': $vuetify.breakpoint.smAndUp,
          'col-4': $vuetify.breakpoint.xsOnly
        }"
        @click="addNewDateVenueDialog = true"
      >
        <ChooseDateItem :key="addNewDateItem.id" :item="addNewDateItem" />
      </div>
      <add-date-venue-dialog
        :visible="addNewDateVenueDialog"
        :event="!selectedEvent ? {} : selectedEvent"
        @close="addNewDateVenueDialog = false"
      />
    </v-row>
    <NextBackButtons
      :first-button-disabled="false"
      :second-button-disabled="true"
      @firstButtonOnClick="setStepperStep(1)"
      @secondButtonOnClick="setStepperStep(3)"
    >
      <template v-slot:firstButton>
        <v-icon left size="20" class="ma-0" style="position:absolute; left:0px"
          >mdi-chevron-left</v-icon
        >
        {{ $vuetify.lang.t('$vuetify.general.back') }}
      </template>
      <template v-slot:secondButton>
        {{ $vuetify.lang.t('$vuetify.general.next') }}
        <v-icon
          left
          size="20"
          class="ma-0"
          style="position:absolute; right:0px"
        >
          mdi-chevron-right
        </v-icon>
      </template>
    </NextBackButtons>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SecondStep',
  components: {
    ChooseDateItem: () =>
      import(
        /* webpackChunkName: "AddEventDialog" */ '@/components/AddingTicket/ChooseDateItem'
      ),
    AddDateVenueDialog: () =>
      import(
        /* webpackChunkName: "AddEventDialog" */ '@/components/AddingTicket/AddDateVenueDialog'
      ),
    NextBackButtons: () =>
      import(
        /* webpackChunkName: "NextBackButtons" */ '@/components/AddingTicket/NextBackButtons'
      )
  },
  data() {
    return {
      addNewDateVenueDialog: false,
      location: '',
      filteredDates: this.dates,
      addNewDateItem: {
        startDate: '',
        venue: 'Lisa ise',
        images: '',
        globalEventId: '99999999'
      }
    }
  },
  computed: {
    ...mapGetters('events', ['events', 'selectedEvent']),
    event: {
      get() {
        return this.selectedEvent
      },
      set(value) {
        this.$store.commit('events/setSelectedEvent', value)
      }
    },
    dates() {
      if (this.events.length > 0 && this.event) {
        return this.events
          .filter(event => {
            return event.globalEventId === this.event.globalEventId
          })
          .filter(event => {
            return (
              new Date(event.startDate).getMonth() !== 3 &&
              new Date(event.startDate * 1000).getYear() !== 20
            )
          })
          .sort((a, b) => {
            return new Date(a.startDate) - new Date(b.startDate)
          })
      }
      return []
    },
    locations() {
      return this.dates.length ? this.dates.map(el => el.venue) : []
    }
  },
  watch: {
    location(newValue) {
      if (newValue) {
        this.filteredDates = this.dates.filter(el => el.venue === newValue)
      }
    }
  },
  created() {
    // this.filteredDates = this.dates

    this.$on('AddDateVenueDialog', event => {
      this.$store.commit('events/setSelectedEvent', event)
      this.$store.commit('events/setStepperStep', 3)
    })
  },
  methods: {
    setSelectedEvent(event) {
      this.$store.commit('events/setSelectedEvent', event)
      this.$store.commit('events/setStepperStep', 3)
    },
    setStepperStep(step) {
      this.$store.commit('events/setStepperStep', step)
    }
  }
}
</script>
